.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height:100%;

}

.outerList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
}

.questionsList {
  display: flex;
  width: 33vw;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(155, 155, 155, 0.136);
  margin-bottom: 20px;
  border-radius: 10px;
  /* align-items: flex-start; */
}

.actionBtn {
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  margin: 5px;
  border-radius: 10px;
  padding: 5px;
  /* background-color: rgba(155, 155, 155, 1.136); */
}


.actionsContainer {
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 10px;
  background-color: #62666e16;
  width: fit-content;
  align-self: center;
  justify-self: center;
  place-self: center;
}

.optionContainer {
  display: flex;
  justify-content: flex-start;
  background-color: #939eb489;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  color: black;
  width: 20vw;
}

@media (max-width: 600px) {
  .optionContainer {
    width: 88vw;
  }

  .questionsList {
    padding: 20px;
    width: 70vw;
  }
}

.questionContainer {
  width: 90%;
  border-radius: 2px;
  display: flex;
  justify-self: center;
  align-items: center;
  background-color: #62666e48;
  color: white;
  margin: 10px;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.option {
  border-style: solid;
  border-radius: 10px;

  padding: 5px;
  margin: 10px
}

.options {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.inputs {

  display: flex;
  flex-direction: column;
}

input {
  align-self: center;
  width: fit-content;
  border-style: solid;
  border-radius: 15px;
  margin: 10px;
  height: 20px;
  padding: 5px
}


.colors {
  border-radius: 20px;
  background-color: #a4a4a4bc;
  padding: 20px;
  align-self: center;
  justify-content: center;
  width: 200px;
  display: flex;
  flex-direction: column;
}
.questionForm{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.form {
  justify-self: center;
  align-self: center;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2vw;
}

.question {
  border-style: solid;
  border-radius: 10px;
  width: 200px;
  margin: 10px;
  padding: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}